export const base = {
  host: 'localhost',
  imageFluxHost: '',
  gtmId: '',
  ENABLE_SENTRY: false,
  ENABLE_MICROCMS: false,
  MICROCMS_API_KEY: '293eb48da305486282ffec5cbc776268a3aa',
  ENABLE_NEWT: false,
  GOOGLE_API_CLIENT_ID: '472729695253-n6rj34julv3oji7pgpvdifk9bj39mfva.apps.googleusercontent.com',
  NEWT_SETTING: {
    SPACE_UID: 'm-660137',
    TOKEN: '1-YF4VVJEQGYDLm6M0TyQBsXvvoU6IA7mlYeIAke3oY',
    NOTICES: {
      APP_UID: 'notices',
      MODEL: {
        NEWS: 'news',
        CATEGORY: 'news-categories',
      },
    },
    DM_NOTICES: {
      APP_UID: 'dm-notice',
      MODEL: {
        NEWS: 'news',
        CATEGORY: 'news-categories',
      },
    },
    FAQ: {
      APP_UID: 'faq',
      MODEL: {
        FAQ: 'faqs',
        CATEGORY: 'faq-categories',
      },
    },
  },
  CONTACT_EMAIL: 'dev@ma-navi.co.jp',
  ADVISORY_EMAIL: 'advisory@ma-navi.co.jp',
  AUDIT_EMAIL: 'audit@mail.ma-navigator.com',
  IS_SHOW_TSR_SLUG: ['demo'],
  ENABLE_ALLIANCE_SLUG: ['demo'],
  NAVI_TENANT_SLUG: 'demo',
  SHICHI_SHICHI_BANK_TENANT_SLUG: '77bank',
  ENABLE_LONGLIST_EXPORT_PATTERN: ['demo'],
  ONLINE_NN_NEEDS_EXPORT_SHEET_URL:
    'https://docs.google.com/spreadsheets/d/1i8DSvznb8lsH-yByacUQq_LnfoRioCISIS5zfo_ymOg/edit?gid=0#gid=0',
}
