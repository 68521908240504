import React from 'react'
import { styled, Alert, Snackbar, IconButton, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFlash } from 'context/flashContext'
import { TimeoutError } from 'components/elements/TimeoutError'

export const Flash: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { flash, setFlash } = useFlash()
  const handleClose = () => setFlash && setFlash({ type: flash?.type || 'success', message: '' })
  return (
    <StyledSnackbar
      open={!!flash?.message || flash?.isTimeout}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      top={flash?.top}
    >
      <StyledAlert
        onClose={handleClose}
        severity={flash?.type}
        action={
          <Stack direction={'row'} alignItems={'center'} whiteSpace={'pre-wrap'}>
            {flash?.isTimeout ? <TimeoutError /> : flash?.message}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        }
      />
    </StyledSnackbar>
  )
}

const StyledSnackbar = styled(Snackbar)<{ top?: number }>(
  ({ top }) => `
  &.MuiPaper-root {
    align-items: center;
  }
  &.MuiSnackbar-root {
    ${top ? `top: ${top}px` : ''}
  }
`
)

const StyledAlert = styled(Alert)(
  ({ theme }) => `
  &.MuiAlert-standardError {
    background-color: ${theme.danger.lightness5} !important;
  }

  &.MuiAlert-standardWarning {
    background-color: ${theme.yellow.lightness5} !important;
  }

  &.MuiAlert-standardSuccess {
    background-color: ${theme.green.lightness5} !important;
  }

  &.MuiAlert-standardInfo {
    background-color: ${theme.primary.lightness5} !important;
  }

  & .MuiAlert-icon {
    padding: 8px 0;
  }
  & .MuiAlert-action {
    padding: 0;
  }
`
)
